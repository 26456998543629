<template lang="pug">
.wrapper
  .container.py-4
    .row
      template(v-if="invitation.success")
        .col-12.text-center
          h1.mb-4 {{ $t('welcome') }}!
          h5.mb-5 <strong> {{ getFullNameByLocale(invitation.inviter.firstName, invitation.inviter.lastName, invitation.inviter.locale) }} </strong> {{ $t('inviteMessage') }} {{ !confirm ? $t('inviteMessageRegister') : '' }}
        .col-6.mx-auto
          form(ref="form")
            template(v-if="!confirm")
              .form-group.row
                label.col-sm-3.mb-0.d-flex.align-items-center(for="firstName") {{ $t('firstName') }}
                .col-sm-9
                  input#firstName.form-control.brand-input(
                    type="text"
                    v-model.trim="firstName"
                    :class="{ 'is-invalid': $v.firstName.$error }"
                  )
                  .text-danger.text-left(v-if="$v.firstName.$error && !$v.firstName.required") {{ $t('requiredField') }}
              .form-group.row
                label.col-sm-3.mb-0.d-flex.align-items-center(for="lastName") {{ $t('lastName') }}
                .col-sm-9
                  input#lastName.form-control.brand-input(
                    type="text"
                    v-model.trim="lastName"
                    :class="{ 'is-invalid': $v.lastName.$error }"
                  )
                  .text-danger.text-left(v-if="$v.lastName.$error && !$v.lastName.required") {{ $t('requiredField') }}
              .form-group.row
                label.col-sm-3.mb-0.d-flex.align-items-center(for="password") {{ $t('password') }}
                .col-sm-9
                  input#password.form-control.brand-input(
                    type="password"
                    v-model.trim="password"
                    :class="{ 'is-invalid': $v.password.$error }"
                  )
                  .text-danger.text-left(v-if="$v.password.$error && !$v.password.required") {{ $t('requiredField') }}
                  .text-danger.text-left(v-if="$v.password.$error && !$v.password.minLength") {{ $t('passwordLength', { length: $v.password.$params.minLength.min }) }}
            om-button(
              primary
              :class="{ 'mx-auto': confirm }"
              @click="register()"
              @keydown.enter="register()"
            ) {{ $t('acceptInvitation') }}
      template(v-else)
        .mb-4 {{ invitation.message }}
</template>

<script>
  import queryParamToCookie from '@/mixins/queryParamToCookie';
  import guessLang from '@/mixins/guessLang';
  import { required, minLength } from 'vuelidate/lib/validators';
  import GET_INVITATION from '@/graphql/GetInvitation.gql';
  import REGISTER_INVITATION from '@/graphql/RegisterInvitation.gql';
  import fullName from '@/mixins/fullName';

  export default {
    mixins: [fullName, queryParamToCookie, guessLang],

    data() {
      return {
        firstName: '',
        lastName: '',
        password: '',
        confirm: false,
        hash: this.$route.params.hash,
        invitation: {
          success: false,
          message: '',
          inviter: { firstName: '', lastName: '', locale: '' },
        },
      };
    },

    mounted() {
      if (this.$route.query.confirm) {
        this.confirm = true;
      }
    },

    created() {
      this.$i18n.locale = this.guessLanguage();
    },

    methods: {
      register() {
        this.$v.$touch();

        if (!this.confirm && this.$v.$invalid) {
          return;
        }

        const locale = this.guessLanguage();

        this.$apollo
          .mutate({
            mutation: REGISTER_INVITATION,
            variables: {
              hash: this.hash,
              input: {
                firstName: this.firstName,
                lastName: this.lastName,
                password: this.password,
                locale,
              },
            },
          })
          .then(() => {
            this.$refs.form.reset();
            this.$notify({
              type: 'success',
              text: this.$t('notifications.saveSuccess'),
            });
            this.$router.push({ name: 'login', params: { lang: locale } });
          })
          .catch((e) => {
            this.$notify({
              type: 'error',
              text: e.message,
            });
          });
      },
    },

    apollo: {
      invitation: {
        query: GET_INVITATION,
        variables() {
          return {
            hash: this.hash,
          };
        },
      },
    },

    validations: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  };
</script>
